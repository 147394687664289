import React, {useEffect, useState} from 'react';
import Container from "../components/Container";
import {renderAvatar} from "../utilities/utilities";
import {
    fetchAllClients,
    fetchAllUsers,
    fetchDiscoveries,
    selectDiscoveries,
    selectError,
    selectIsLoading,
    selectUsers,
    selectClients
} from "../components/Discovery/discoveryCallSlice";
import {useDispatch, useSelector} from "react-redux";
import Link from "../components/Link";

const AuditDashboard = () => {

    const discoveries = useSelector(selectDiscoveries);
    const isLoading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    const users = useSelector(selectUsers);
    const clients = useSelector(selectClients);

    let [clientsToShow, setClientsToShow] = useState(clients);

    // let [page, setPage] = useState(0)

    const dispatch = useDispatch();

    useEffect(() => {
        // const pageSize = 25;
        // const offset = pageSize * page;
        // const limit = pageSize;
        // dispatch(fetchDiscoveries(/*{offset, limit}*/));
        dispatch(fetchAllUsers());
        dispatch(fetchAllClients());
    }, [dispatch /*, page*/]);

    useEffect(() => {
        setClientsToShow(clients);
    }, [clients]);

    const viewDiscoCall = (id) => {
        if (typeof id !== 'undefined' && id !== null) {
            window.location.href = `/discovery-call?id=${id}`;
        }
    };

    const renderRow = (item) => {
        return (
            <div key={`id-${item.name}`} className="dash-row">
                <div className="dash-row-cell logo">
                    <img src="../img/logo-placeholder.jpg" alt={item.name} style={{width: 207, height: 113}}/>
                </div>
                <div className="dash-row-cell owner">
                    <div className="owner-info">
                        <span className="title">{item.owner.title}</span>
                        <div className="avatar">
                            <img src={item.owner.image} alt={item.owner.name} />
                            <span>{item.owner.name}</span>
                        </div>
                    </div>
                    <a className="email" href={`mailto:${item.owner.email}`}>{item.owner.email}</a>
                </div>
                <div className="dash-row-cell actions">
                    <div className="buttons">
                        <div className="action-btn">View Disco Call</div>
                        <div className="action-btn">View Audit</div>
                    </div>
                    <div className="status" style={{backgroundColor: item.statusColor}}> {item.status}</div>
                </div>
            </div>
        )
    };

    const renderDiscoCallBtn = (discoId) => {
        const style = {textAlign: 'center'};
        if (discoId !== null) {
            return (
                <div className="action-btn" style={style} onClick={() => viewDiscoCall(discoId)}>View Disco Call</div>
            )
        } else {
            return (
                <div className="action-btn warning" style={style}>No Disco Call</div>
            )
        }
    };

    const renderProfile = (authorId) => {
        let user = users.filter(u => {
            return u.id === authorId;
        });

        if (user.length > 0) {
            user = user[0];
        }

        return (
            <div className="profile">
                <span className="owner-label">Owner</span>
                {renderAvatar(user)}
                <span className="owner-name">{user.name}</span>
            </div>
        )
    };

    const renderTempRow = (item) => {
        let discoveryId;
        if (item.audits.length > 0 && item.audits[0].discovery) {
            discoveryId = item.audits[0].discovery.id;
        }
        //const discoId = item.audits.length > 0 && item.audits[0].id ? item.audits[0].id : null;
        return (
            <div key={`id-${item.name}`} className="dash-row columns is-centered is-vcentered" style={{marginBottom: 40}}>
                <div className="dash-row-cell column is-4 logo" style={{textAlign: 'left'}}>
                    <span className="title" style={{fontSize: 24, marginBottom: 0, wordBreak: 'normal'}}>{item.name}</span>
                </div>
                <div className="dash-row-cell column is-4 owner">
                    <div className="owner-info">
                        {renderProfile(item.author.id)}
                    </div>
                    {/*<a className="email" >[email address]</a>*/}
                </div>
                <div className="dash-row-cell column is-4 actions">
                    <div className="buttons" style={{marginBottom: 0, textAlign: 'right'}}>
                        {renderDiscoCallBtn(discoveryId)}
                        {/*<div className="action-btn">View Audit</div>*/}
                    </div>
                    {/*<div className="status" style={{backgroundColor: item.statusColor}}> {item.status}</div>*/}
                </div>
            </div>
        )
    };

    const renderRows = () => {
        if (clients.length > 0) {
            return clientsToShow.map(i => {
                return renderTempRow(i);
            })
        } else {
            setTimeout(() => {
                return (
                    <div>
                        <p style={{textAlign: 'center'}}>No clients to show</p>
                    </div>
                )
            }, 500);
        }
    };

    const handleFilterClients = (e) => {
        const {value} = e.target;
        let filtered = [];

        if (value === 'all') {
            filtered = clients;
        } else {
            filtered = clients.filter(client => {
                return client.author.id === parseInt(value);
            })
        }
        setClientsToShow(filtered);
    };

    const renderHeader = () => {
        const clientOwnerIds = clients.map(client => {
            return client.author.id;
        });

        const clientOwners = users.filter(user => {
            return clientOwnerIds.includes(user.id);
        }).sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        });

        return (
            <div className="dash-header">
                <div className="columns is-multiline is-centered is-vcentered">
                    <div className="column" style={{paddingLeft: 0}}>
                        <div className="select is-rounded owners-dropdown">
                            <select onChange={(e) => handleFilterClients(e)}>
                                <option value="all">All Owners</option>
                                {clientOwners.map(owner => {
                                    return <option key={`option-${owner.id}`} value={owner.id}>{owner.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="column">
                        <Link className="btn btn-default" to="/discovery-call/">+ Discovery Call</Link>
                    </div>
                    <div className="column">
                        <Link className="btn btn-default" to="/">+ Audit</Link>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <Container mx='auto'>
            <div className="dashboard-container">
                {renderHeader()}
                {renderRows()}
            </div>
        </Container>
    )
};

export default AuditDashboard;